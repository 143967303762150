import { getAccessToken } from '@/services/axios-instance';
import { Operations } from '../enums/Operations';
import { UIOperations } from '../enums/UIOperations';
import { getCampusId } from '../helpers/campus';
import { parseAuthToken } from '../helpers/token';
import { hasPermission } from '../helpers/userPermission';
import RolesJSON from '@/utils/constants/roles.json';

interface RolePermissions {
  name: string;
  permissions: (Operations | UIOperations)[];
}

export interface UserPermissionsType {
  [key: string]: RolePermissions;
}

export const ALL_CAMPUSES_SUFFIX = '_all-campuses';

export const addAllCampusesPermission = (permission: Operations) => {
  return `${permission}${ALL_CAMPUSES_SUFFIX}` as Operations;
};

export const showAllCampusesOption = (permission: Operations) =>
  hasPermission(addAllCampusesPermission(permission));

export const getCampusSearchValue = (permission: Operations) =>
  hasPermission(addAllCampusesPermission(permission)) ? 'all' : getCampusId();

export const Roles: UserPermissionsType = JSON.parse(JSON.stringify(RolesJSON));

export const getStrongestRole = (): number => {
  const parsedAuthToken = parseAuthToken(getAccessToken());
  const isE12Admin = Boolean(parsedAuthToken.managementRoles.length > 0);

  if (!isE12Admin) {
    const campusID = getCampusId();
    const campusRoles = parsedAuthToken.roles.filter(
      (role) => role.campusID === campusID
    );

    if (campusRoles.length > 0) {
      return Math.min(...campusRoles.map((role) => Math.min(...role.values)));
    }
  }
  return Math.min(...parsedAuthToken.managementRoles);
};

export const ROLE_MAP = new Map<string, number[]>([
  ['E12', [1, 2, 3]],
  ['FOUNDER', [5, 6]],
  ['MANAGEMENT', [5, 6, 22, 23]],
  ['ACCOUNTING', [16, 17, 18, 29]],
  ['IK', [22, 23]],
]);

export const hasRole = (role: number, roleName: string): boolean => {
  const roleIds = ROLE_MAP.get(roleName);
  return roleIds ? roleIds.includes(role) : false;
};

export const isE12Admin = (role: number) => hasRole(role, 'E12');
export const isManagementRole = (role: number) => hasRole(role, 'MANAGEMENT');
export const isFounderRole = (role: number) => hasRole(role, 'FOUNDER');
export const isAccountingRole = (role: number) => hasRole(role, 'ACCOUNTING');
export const isIKRole = (role: number) => hasRole(role, 'IK');
// Berk: Yedek için burada tutalım.
// export const OldPermissions: UserPermissionsType = {
//   1: {
//     name: 'E12 Admin',
//     operations: [
//       UIOperations.ShowAllCampusesOption,
//       Operations.GetEnrichedOrganizations,
//       Operations.CreateOrganization,
//       Operations.GetOrganization,
//       Operations.UpdateOrganization,
//       Operations.CreateCampus,
//       Operations.UpdateCampus,
//       Operations.GetCampusesOverview,
//       Operations.CreateClass,
//       Operations.GetCampusDepartments,
//       Operations.GetCampusGrades,
//       Operations.GetCampusGradeClasses,
//       Operations.GetCurriculumList,
//       Operations.CreateCurriculum,
//       Operations.UpdateCurriculum,
//       Operations.DeleteCurriculum,
//       Operations.GetCurriculum,
//       Operations.CreateLesson,
//       Operations.GetLessonList,
//       Operations.UpdateLesson,
//       Operations.DeleteLesson,
//       Operations.GetLessonDetail,
//       Operations.GetLearningDomainList,
//       Operations.CreateLearningDomain,
//       Operations.UpdateLearningDomain,
//       Operations.DeleteLearningDomain,
//       Operations.GetLearningDomain,
//       Operations.GetSubLearningDomainList,
//       Operations.CreateSubLearningDomain,
//       Operations.UpdateSubLearningDomain,
//       Operations.DeleteSubLearningDomain,
//       Operations.GetSubLearningDomain,
//       Operations.GetSubjectList,
//       Operations.CreateSubject,
//       Operations.UpdateSubject,
//       Operations.DeleteSubject,
//       Operations.GetSubject,
//       Operations.GetLearningOutcomeList,
//       Operations.CreateLearningOutcome,
//       Operations.UpdateLearningOutcome,
//       Operations.DeleteLearningOutcome,
//       Operations.GetLearningOutcome,
//       Operations.GetSubLearningOutcomeList,
//       Operations.CreateSubLearningOutcome,
//       Operations.UpdateSubLearningOutcome,
//       Operations.DeleteSubLearningOutcome,
//       Operations.GetSubLearningOutcome,
//       Operations.GetSpecialties,
//       Operations.CreateSpecialty,
//       Operations.GetSpecialty,
//       Operations.UpdateSpecialty,
//       Operations.DeleteSpecialty,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.DeleteClass,
//       Operations.UpdateClass,
//       Operations.GetClassDetail,
//       Operations.GetPlaceList,
//       Operations.CreatePlace,
//       Operations.GetPlace,
//       Operations.DeletePlace,
//       Operations.UpdatePlace,
//       Operations.CreateLessonContent,
//       Operations.GetLessonContents,
//       Operations.UpdateLessonContent,
//       Operations.GetLessonContent,
//       Operations.DeleteLessonContent,
//       Operations.GetVideos,
//       Operations.CreateVideo,
//       Operations.GetVideoDetail,
//       Operations.UpdateVideo,
//       Operations.DeleteVideo,
//       Operations.GetAnnualPlanList,
//       Operations.CreateAnnualPlan,
//       Operations.UpdateAnnualPlan,
//       Operations.GetAnnualPlan,
//       Operations.DeleteAnnualPlan,
//       Operations.UpdateCurrentWeekAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.CreateAnnualPlanWeeks,
//       Operations.UpdateAnnualPlanWeeks,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//       Operations.GetStudentPersonalInformation,
//       Operations.GetParentInformation,
//       Operations.GetStudentRegistrationInfo,
//       Operations.ChangeStudentPassword,
//       Operations.GetStudentList,
//       Operations.GetStudentByID,
//       Operations.CreateUser,
//       Operations.DeleteStudent,
//       Operations.ExportStudents,
//       Operations.UpdateStudent,
//       Operations.GetEmployeeList,
//       Operations.GetEmployeeDetail,
//       Operations.GetEmployeePersonalInfo,
//       Operations.GetEmployeeRegistrationInfo,
//       Operations.ChangeEmployeePassword,
//       Operations.UpdateEmployee,
//       Operations.DeleteEmployee,
//       Operations.GetContractInformation,
//       Operations.ExportEmployees,
//       // mocks
//       Operations.GetAttendanceList,
//       Operations.UpdateClass,
//       Operations.GetAttendanceDetail,
//       Operations.DeleteClass,
//       Operations.GetClassAnnouncementList,
//       Operations.UpdateClassAnnouncement,
//       Operations.DeleteClassAnnouncement,
//       Operations.GetDemandList,
//       Operations.UpdateDemand,
//       Operations.DeleteDemand,
//       Operations.GetGroupList,
//       Operations.CreateGroup,
//       Operations.UpdateGroup,
//       Operations.DeleteGroup,
//       Operations.GetGroupDetail,
//       Operations.CreateGroupMeeting,
//       Operations.UpdateGroupMeeting,
//       Operations.CreateGroupDemand,
//       Operations.UpdateGroupDemand,
//       Operations.CreateGroupAnnouncement,
//       Operations.GetSocietyList,
//       Operations.CreateSociety,
//       Operations.UpdateSociety,
//       Operations.DeleteSociety,
//       Operations.GetSocietyDetail,
//       Operations.CreateSocietyMeeting,
//       Operations.UpdateSocietyMeeting,
//       Operations.CreateSocietyDemand,
//       Operations.UpdateSocietyDemand,
//       Operations.CreateSocietyAnnouncement,
//       Operations.CreateAttendance,
//       Operations.UpdateAttendance,
//       Operations.DeleteAttendance,
//       Operations.GetTakeAttendance,
//       Operations.GetAttendanceDetail,
//       Operations.GetMeetingList,
//       Operations.UpdateMeeting,
//       Operations.DeleteMeeting,
//       Operations.GetMeetingDetail,
//       Operations.GetCampusAnnouncementList,
//       Operations.CreateCampusAnnouncement,
//       Operations.UpdateCampusAnnouncement,
//       Operations.DeleteCampusAnnouncement,
//       Operations.GetCampusAnnouncementDetail,
//       Operations.GetUnitTimelineList,
//       Operations.UpdateUnitTimeline,
//       Operations.DeleteUnitTimeline,
//       Operations.GetUnitTimeline,
//       Operations.GetClassScheduleList,
//       Operations.UpdateClassSchedule,
//       Operations.DeleteClassSchedule,
//       Operations.GetClassSchedule,
//       Operations.GetAcademicYearList,
//       Operations.UploadFile,
//     ],
//   },
//   2: {
//     name: 'E12 Satış Sonrası Destek Takımı',
//     operations: [
//       Operations.GetEnrichedOrganizations,
//       Operations.CreateOrganization,
//       Operations.GetOrganization,
//       Operations.UpdateOrganization,
//       Operations.CreateCampus,
//       Operations.UpdateCampus,
//       Operations.GetCampusesOverview,
//       Operations.CreateClass,
//       Operations.GetCampusDepartments,
//       Operations.GetCampusGrades,
//       Operations.GetCampusGradeClasses,
//       Operations.GetCurriculumList,
//       Operations.CreateCurriculum,
//       Operations.UpdateCurriculum,
//       Operations.DeleteCurriculum,
//       Operations.GetCurriculum,
//       Operations.CreateLesson,
//       Operations.GetLessonList,
//       Operations.UpdateLesson,
//       Operations.DeleteLesson,
//       Operations.GetLessonDetail,
//       Operations.GetLearningDomainList,
//       Operations.CreateLearningDomain,
//       Operations.UpdateLearningDomain,
//       Operations.DeleteLearningDomain,
//       Operations.GetLearningDomain,
//       Operations.GetSubLearningDomainList,
//       Operations.CreateSubLearningDomain,
//       Operations.UpdateSubLearningDomain,
//       Operations.DeleteSubLearningDomain,
//       Operations.GetSubLearningDomain,
//       Operations.GetSubjectList,
//       Operations.CreateSubject,
//       Operations.UpdateSubject,
//       Operations.DeleteSubject,
//       Operations.GetSubject,
//       Operations.GetLearningOutcomeList,
//       Operations.CreateLearningOutcome,
//       Operations.UpdateLearningOutcome,
//       Operations.DeleteLearningOutcome,
//       Operations.GetLearningOutcome,
//       Operations.GetSubLearningOutcomeList,
//       Operations.CreateSubLearningOutcome,
//       Operations.UpdateSubLearningOutcome,
//       Operations.DeleteSubLearningOutcome,
//       Operations.GetSubLearningOutcome,
//       Operations.GetSpecialties,
//       Operations.CreateSpecialty,
//       Operations.GetSpecialty,
//       Operations.UpdateSpecialty,
//       Operations.DeleteSpecialty,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.DeleteClass,
//       Operations.UpdateClass,
//       Operations.GetClassDetail,
//       Operations.GetPlaceList,
//       Operations.CreatePlace,
//       Operations.GetPlace,
//       Operations.DeletePlace,
//       Operations.UpdatePlace,
//       Operations.GetLessonContents,
//       Operations.CreateLessonContent,
//       Operations.UpdateLessonContent,
//       Operations.GetLessonContent,
//       Operations.DeleteLessonContent,
//       Operations.GetVideos,
//       Operations.CreateVideo,
//       Operations.GetVideoDetail,
//       Operations.UpdateVideo,
//       Operations.DeleteVideo,
//       Operations.GetAnnualPlanList,
//       Operations.CreateAnnualPlan,
//       Operations.UpdateAnnualPlan,
//       Operations.GetAnnualPlan,
//       Operations.DeleteAnnualPlan,
//       Operations.UpdateCurrentWeekAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.CreateAnnualPlanWeeks,
//       Operations.UpdateAnnualPlanWeeks,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   3: {
//     name: 'E12 Muhasebe Takımı',
//     operations: [
//       Operations.GetEnrichedOrganizations,
//       Operations.GetCurriculumList,
//       Operations.GetLessonList,
//       Operations.GetLearningDomainList,
//       Operations.GetSubLearningDomainList,
//       Operations.GetSubjectList,
//       Operations.GetLearningOutcomeList,
//       Operations.GetSubLearningOutcomeList,
//       Operations.GetSpecialties,
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//     ],
//   },
//   4: {
//     name: 'E12 Finans Takımı',
//     operations: [
//       Operations.GetEnrichedOrganizations,
//       Operations.GetCurriculumList,
//       Operations.GetLessonList,
//       Operations.GetLearningDomainList,
//       Operations.GetSubLearningDomainList,
//       Operations.GetSubjectList,
//       Operations.GetLearningOutcomeList,
//       Operations.GetSubLearningOutcomeList,
//       Operations.GetSpecialties,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetCampusesOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//     ],
//   },
//   5: {
//     name: 'E12 Müdür',
//     operations: [
//       Operations.GetEnrichedOrganizations,
//       Operations.CreateOrganization,
//       Operations.GetOrganization,
//       Operations.UpdateOrganization,
//       Operations.CreateCampus,
//       Operations.UpdateCampus,
//       Operations.GetCampusesOverview,
//       Operations.CreateClass,
//       Operations.GetCampusDepartments,
//       Operations.GetCampusGrades,
//       Operations.GetCampusGradeClasses,
//       Operations.GetCurriculumList,
//       Operations.CreateCurriculum,
//       Operations.UpdateCurriculum,
//       Operations.DeleteCurriculum,
//       Operations.GetCurriculum,
//       Operations.CreateLesson,
//       Operations.GetLessonList,
//       Operations.UpdateLesson,
//       Operations.DeleteLesson,
//       Operations.GetLessonDetail,
//       Operations.GetLearningDomainList,
//       Operations.CreateLearningDomain,
//       Operations.UpdateLearningDomain,
//       Operations.DeleteLearningDomain,
//       Operations.GetLearningDomain,
//       Operations.GetSubLearningDomainList,
//       Operations.CreateSubLearningDomain,
//       Operations.UpdateSubLearningDomain,
//       Operations.DeleteSubLearningDomain,
//       Operations.GetSubLearningDomain,
//       Operations.GetSubjectList,
//       Operations.CreateSubject,
//       Operations.UpdateSubject,
//       Operations.DeleteSubject,
//       Operations.GetSubject,
//       Operations.GetLearningOutcomeList,
//       Operations.CreateLearningOutcome,
//       Operations.UpdateLearningOutcome,
//       Operations.DeleteLearningOutcome,
//       Operations.GetLearningOutcome,
//       Operations.GetSubLearningOutcomeList,
//       Operations.CreateSubLearningOutcome,
//       Operations.UpdateSubLearningOutcome,
//       Operations.DeleteSubLearningOutcome,
//       Operations.GetSubLearningOutcome,
//       Operations.GetSpecialties,
//       Operations.CreateSpecialty,
//       Operations.GetSpecialty,
//       Operations.UpdateSpecialty,
//       Operations.DeleteSpecialty,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.DeleteClass,
//       Operations.UpdateClass,
//       Operations.GetClassDetail,
//       Operations.GetPlaceList,
//       Operations.CreatePlace,
//       Operations.GetPlace,
//       Operations.DeletePlace,
//       Operations.UpdatePlace,
//       Operations.GetLessonContents,
//       Operations.CreateLessonContent,
//       Operations.UpdateLessonContent,
//       Operations.GetLessonContent,
//       Operations.DeleteLessonContent,
//       Operations.GetVideos,
//       Operations.CreateVideo,
//       Operations.GetVideoDetail,
//       Operations.UpdateVideo,
//       Operations.DeleteVideo,
//       Operations.GetAnnualPlanList,
//       Operations.CreateAnnualPlan,
//       Operations.UpdateAnnualPlan,
//       Operations.GetAnnualPlan,
//       Operations.DeleteAnnualPlan,
//       Operations.UpdateCurrentWeekAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.CreateAnnualPlanWeeks,
//       Operations.UpdateAnnualPlanWeeks,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   6: {
//     name: 'E12 Destek Takımı',
//     operations: [
//       Operations.GetEnrichedOrganizations,
//       Operations.CreateOrganization,
//       Operations.GetOrganization,
//       Operations.UpdateOrganization,
//       Operations.CreateCampus,
//       Operations.UpdateCampus,
//       Operations.GetCampusesOverview,
//       Operations.CreateClass,
//       Operations.GetCampusDepartments,
//       Operations.GetCampusGrades,
//       Operations.GetCampusGradeClasses,
//       Operations.GetCurriculumList,
//       Operations.CreateCurriculum,
//       Operations.UpdateCurriculum,
//       Operations.DeleteCurriculum,
//       Operations.GetCurriculum,
//       Operations.CreateLesson,
//       Operations.GetLessonList,
//       Operations.UpdateLesson,
//       Operations.DeleteLesson,
//       Operations.GetLessonDetail,
//       Operations.GetLearningDomainList,
//       Operations.CreateLearningDomain,
//       Operations.UpdateLearningDomain,
//       Operations.DeleteLearningDomain,
//       Operations.GetLearningDomain,
//       Operations.GetSubLearningDomainList,
//       Operations.CreateSubLearningDomain,
//       Operations.UpdateSubLearningDomain,
//       Operations.DeleteSubLearningDomain,
//       Operations.GetSubLearningDomain,
//       Operations.GetSubjectList,
//       Operations.CreateSubject,
//       Operations.UpdateSubject,
//       Operations.DeleteSubject,
//       Operations.GetSubject,
//       Operations.GetLearningOutcomeList,
//       Operations.CreateLearningOutcome,
//       Operations.UpdateLearningOutcome,
//       Operations.DeleteLearningOutcome,
//       Operations.GetLearningOutcome,
//       Operations.GetSubLearningOutcomeList,
//       Operations.CreateSubLearningOutcome,
//       Operations.UpdateSubLearningOutcome,
//       Operations.DeleteSubLearningOutcome,
//       Operations.GetSubLearningOutcome,
//       Operations.GetSpecialties,
//       Operations.CreateSpecialty,
//       Operations.GetSpecialty,
//       Operations.UpdateSpecialty,
//       Operations.DeleteSpecialty,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.DeleteClass,
//       Operations.UpdateClass,
//       Operations.GetClassDetail,
//       Operations.GetPlaceList,
//       Operations.CreatePlace,
//       Operations.GetPlace,
//       Operations.DeletePlace,
//       Operations.UpdatePlace,
//       Operations.GetLessonContents,
//       Operations.CreateLessonContent,
//       Operations.UpdateLessonContent,
//       Operations.GetLessonContent,
//       Operations.DeleteLessonContent,
//       Operations.GetVideos,
//       Operations.CreateVideo,
//       Operations.GetVideoDetail,
//       Operations.UpdateVideo,
//       Operations.DeleteVideo,
//       Operations.GetAnnualPlanList,
//       Operations.CreateAnnualPlan,
//       Operations.UpdateAnnualPlan,
//       Operations.GetAnnualPlan,
//       Operations.DeleteAnnualPlan,
//       Operations.UpdateCurrentWeekAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.CreateAnnualPlanWeeks,
//       Operations.UpdateAnnualPlanWeeks,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   7: {
//     name: 'E12 İçerik Takımı',
//     operations: [
//       Operations.GetEnrichedOrganizations,
//       Operations.GetCurriculumList,
//       Operations.GetLessonList,
//       Operations.GetLearningDomainList,
//       Operations.GetSubLearningDomainList,
//       Operations.GetSubjectList,
//       Operations.GetLearningOutcomeList,
//       Operations.GetSubLearningOutcomeList,
//       Operations.GetSpecialties,
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.CreateLessonContent,
//       Operations.UpdateLessonContent,
//       Operations.GetLessonContent,
//       Operations.DeleteLessonContent,
//       Operations.GetVideos,
//       Operations.CreateVideo,
//       Operations.GetVideoDetail,
//       Operations.UpdateVideo,
//       Operations.DeleteVideo,
//       Operations.GetAnnualPlanList,
//       Operations.CreateAnnualPlan,
//       Operations.UpdateAnnualPlan,
//       Operations.GetAnnualPlan,
//       Operations.DeleteAnnualPlan,
//       Operations.UpdateCurrentWeekAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.CreateAnnualPlanWeeks,
//       Operations.UpdateAnnualPlanWeeks,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   8: {
//     name: 'Kurucu',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   9: {
//     name: 'Kurucu Temsilcisi',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   10: {
//     name: 'Kurucu Asistanı',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   11: {
//     name: 'Genel Müdür',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   12: {
//     name: 'Müdür',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   13: {
//     name: 'Müdür Yardımcısı Kıdemli',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   14: {
//     name: 'Müdür Yardımcısı',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   15: {
//     name: 'Genel Koordinatör',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   16: {
//     name: 'Koordinatör',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   17: {
//     name: 'Zümre Başkanı',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   18: {
//     name: 'Branş Öğretmeni',
//     operations: [
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   19: {
//     name: 'Muhasebe Müdürü',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//     ],
//   },
//   20: {
//     name: 'Muhasebe Personeli Kıdemli',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//     ],
//   },
//   21: {
//     name: 'Muhasebe Personeli',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//     ],
//   },
//   22: {
//     name: 'Destek Personel Müdürü',
//     operations: [Operations.GetPlaceList],
//   },
//   23: {
//     name: 'Destek Personeli Kıdemli',
//     operations: [Operations.GetPlaceList],
//   },
//   24: {
//     name: 'Destek Personeli',
//     operations: [],
//   },
//   25: {
//     name: 'İnsan Kaynakları Müdürü',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   26: {
//     name: 'İnsan Kaynakları',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   27: {
//     name: 'Kayıt Personeli',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   28: {
//     name: 'Satın Alma Müdürü',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   29: {
//     name: 'Satın Alma Personeli',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   30: {
//     name: 'Danışma Görevlisi Kıdemli',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   31: {
//     name: 'Danışma Görevlisi',
//     operations: [
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   32: {
//     name: 'Finans Müdürü',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   33: {
//     name: 'Ölçme Değerlendirme Müdürü',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetPlaceList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   34: {
//     name: 'Ölçme Değerlendirme Personeli',
//     operations: [
//       Operations.GetCampusesOverview,
//       Operations.GetCampusesDepartmentsOverview,
//       Operations.GetClassList,
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   35: {
//     name: 'Servis Personeli',
//     operations: [],
//   },
//   36: {
//     name: 'Kat Görevlisi',
//     operations: [Operations.GetPlaceList],
//   },
//   37: {
//     name: 'Revir Personeli',
//     operations: [Operations.GetPlaceList],
//   },
//   38: {
//     name: 'Yemekhane Müdürü',
//     operations: [Operations.GetPlaceList],
//   },
//   39: {
//     name: 'Yemekhane Personeli',
//     operations: [],
//   },
//   40: {
//     name: 'Güvenlik Müdürü',
//     operations: [Operations.GetPlaceList],
//   },
//   41: {
//     name: 'Güvenlik Personeli',
//     operations: [],
//   },
//   42: {
//     name: 'Rehberlik',
//     operations: [
//       Operations.GetLessonContents,
//       Operations.GetVideos,
//       Operations.GetAnnualPlanList,
//       Operations.GetAnnualPlan,
//       Operations.GetAnnualPlanWeekList,
//       Operations.GetLiveLessons,
//       Operations.CreateLiveLesson,
//       Operations.GetLiveLessonDetail,
//       Operations.UpdateLiveLesson,
//       Operations.DeleteLiveLesson,
//     ],
//   },
//   43: {
//     name: 'Öğrenci',
//     operations: [],
//   },
//   44: {
//     name: 'Veli',
//     operations: [],
//   },
// };
